export const GalleryData = [
  {
    src: "./images/jerseyville-property.jpeg",
    title: "Acreage",
  },
  {
    src: "./images/jerseyville-property2.jpeg",
    title: "",
  },
  {
    src: "./images/jerseyville-property4.jpeg",
    title: "",
  },
  {
    src: "./images/jerseyville-property5.jpeg",
    title: "",
  },
  {
    src: "./images/jerseyville-property6.jpeg",
    title: "",
  },
  {
    src: "./images/jerseyville-pool.jpeg",
    title: "",
  },
  {
    src: "./images/jerseyville-property-garage.jpeg",
    title: "",
  },
  {
    src: "./images/jerseyville-foyer.jpeg",
    title: "Foyer",
  },
  {
    src: "./images/jerseyville-kitchen.jpeg",
    title: "Kitchen",
  },
  {
    src: "./images/jerseyville-kitchen2.jpeg",
    title: "",
  },
  {
    src: "./images/jerseyville-dr1.jpeg",
    title: "Dining Room",
  },
  {
    src: "./images/jerseyville-dr2.jpeg",
    title: "",
  },
  {
    src: "./images/jerseyville-upstairshall.jpeg",
    title: "Second floor",
  },
  {
    src: "./images/jerseyville-sparebdrm1.jpeg",
    title: "",
  },
  {
    src: "./images/jerseyville-sparebdrm2.jpeg",
    title: "",
  },
  {
    src: "./images/jerseyville-bath.jpeg",
    title: "",
  },
  {
    src: "./images/jerseyville-office2.jpeg",
    title: "",
  },
  {
    src: "./images/jerseyville-bdrm1.jpeg",
    title: "Master Bedroom",
  },
  {
    src: "./images/jerseyville-bedroom2.jpeg",
    title: "",
  },
  {
    src: "./images/jerseyville-masterbath.jpeg",
    title: "",
  },
  {
    src: "",
    title: "",
  },
];
